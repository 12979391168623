/* eslint-disable no-console,no-restricted-syntax */
import Vue from 'vue';
import App from './App.vue';
import Axios from 'axios'
import VueNoty from 'vuejs-noty'
import BootstrapVue from 'bootstrap-vue';
import VueCookies from 'vue-cookies'

import * as constants from './constants.ts';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import { SchedulePlugin } from '@syncfusion/ej2-vue-schedule';
import { registerLicense } from '@syncfusion/ej2-base';
Vue.use(SchedulePlugin);
registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdURiWXtadXxTT2Ff;Mgo+DSMBMAY9C3t2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdURiWXtadXxSRmZf');

import store from './store.js'; 
import router from './router';

Vue.use(VueCookies);

Vue.use(BootstrapVue);
Vue.use(VueNoty, {
    theme: 'nest',
    timeout: 2000,
    progressBar: true,
    layout: 'bottomCenter'
});

const { DateTime, Interval } = require("luxon");
Vue.prototype.$luxonDateTime = DateTime;
Vue.prototype.$luxonInterval = Interval;

Vue.prototype.$http = Axios;
Vue.prototype.axios = Axios;
Vue.prototype.$const = constants;


Vue.filter('convertDateTime', value => {
    return value;
});

import './sass/app.scss';
import i18n from './i18n';

Axios.defaults.withCredentials = true;

Axios.interceptors.request.use(function (config) {
    // Do something before request is sent    
    let hostname = process.env.VUE_APP_SERVER !== "" ? process.env.VUE_APP_SERVER : '/server/rest';
    let lang = store.getters['lang/locale'];
    config.headers.common['x-lang'] = lang;
    if(config.url.includes(hostname) && config.url.includes("/message/link/"))
    {
        let bearer_token = "";
        let link_token_cookie = Vue.$cookies.get("link_token");
        let link_token = store.getters['user/link_token'];
        if(link_token_cookie != undefined)
        {
            link_token = link_token_cookie;
        }
        if(link_token != undefined)
        {
            if(link_token != "")
            {
                bearer_token = link_token;
            }
        }
        config.headers.Authorization =  bearer_token ? `Bearer ${bearer_token}` : '';

        return config;
    }else{
        let hostname = process.env.VUE_APP_SERVER !== "" ? process.env.VUE_APP_SERVER : '/server/rest';
        if(config.url.includes(hostname))
        {
            let bearer_token = "";
            let token = Vue.$cookies.get("token");
            if(token != undefined)
            {
                bearer_token = token;
            }
            config.headers.Authorization =  bearer_token ? `Bearer ${bearer_token}` : '';
            return config;
        }else{            
            return config;
        }
    }    
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

Axios.interceptors.response.use(
    (response) => {        
        if(response.data.validations != undefined)
        {
            if(response.data.validations.validations.length != 0)
            {
                store.dispatch("errors/addValidations", response.data.validations.validations);
            }
            if(response.data.status == "Error"){
                return Promise.reject(new Error('fail_4_3'));           
            }            
            response.data = response.data.responseObject;
        }
        return response;        
    },
    (error) => {
        if(error.response.status != undefined)
        {
            if(error.response.status === 400)
            {
                if(error.response.data.status != undefined)
                {
                    if(error.response.data.validations.validations.length != 0)
                    {
                        store.dispatch("errors/addValidations", error.response.data.validations.validations);
                    }
                    return Promise.reject(new Error('fail_4_4'));
                }            
            }         
            if (error.response.status === 504) {
                window.location.href = "/server-not-responding";
            }
            if (error.response.status === 502) {
                window.location.href = "/server-not-responding";
            }        
        }
        return Promise.reject(error)
    }
);

import '@/mixins/generalMixin';

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
